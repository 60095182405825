<template>
  <div class="box" style="background-color: #f8fcff;">

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="title-body">
          <span class="title-top">一键报废</span>
          <span class="title-bottom" @click="goScrapWarn">其他报废路径></span>
        </div>
        <el-steps class="steps-wrapper" align-center :active="active">
          <el-step description="填写车主信息" icon="el-icon-s-help"></el-step>
          <el-step description="填写车辆信息" icon="el-icon-s-help"></el-step>
          <el-step description="上传相关图片" icon="el-icon-s-help"></el-step>
          <el-step description="提交成功" icon="el-icon-s-help"></el-step>
        </el-steps>
      </div>
      <div class="box">
        <el-form :rules="rules" ref="form" :model="form" label-width="80px">
          <el-form-item class="from-item" label="姓名:" prop="name">
            <el-input placeholder="请输入姓名" v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item class="from-item" label="车牌号:" prop="platenumber">
            <el-input placeholder="请输入车牌号" v-model="form.platenumber"></el-input>
          </el-form-item>
          <el-form-item class="from-item" label="电话:" prop="phone">
            <el-input placeholder="请输入11位手机号码" v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item class="from-item" label="地区:" prop="address">
            <el-select v-model="form.province" placeholder="省" style="width: 90px;">
              <el-option v-for="provice in provinceList" :key="provice.value" :value="provice.value"
                :label="provice.label"></el-option>
            </el-select>
            <el-select v-model="form.city" placeholder="市" style="width: 90px;margin-left:13px;">
              <el-option v-for="city in cityList" :key="city.value" :value="city.value" :label="city.label"></el-option>
            </el-select>
            <el-select v-model="form.area" placeholder="区" style="width: 90px;margin-left:13px;">
              <el-option v-for="area in areaList" :key="area.value" :value="area.value" :label="area.label"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="from-item" label="拖车详细地址:" prop="addressInfo">
            <el-input placeholder="请输入详细地址" v-model="form.addressInfo"></el-input>
          </el-form-item>
          <el-form-item class="scrap-textarea from-item" label="备注:">
            <el-input type="textarea" placeholder="请详细描述" v-model="form.remarks"></el-input>
          </el-form-item>
          <el-form-item class="from-item">
            <p class="from-item-radio">
              <el-radio-group @change="onChangeRadio" v-model="radio">
                <el-radio :label="1">车主自送车辆</el-radio>
                <el-radio :label="2">预约拖车</el-radio>
              </el-radio-group>
            </p>
          </el-form-item>

          <el-form-item class="from-item">
            <div class="checked-wrapper">
              <el-checkbox v-model="checked">我已阅读了
                <span style="color: #24A2AA;" @click="goScrapData">
                  《报废汽车所需资料（必读）》
                </span>
              </el-checkbox>
              <span v-if="isShowCheckedMsg">请勾选协议</span>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button class="bottom-btn" @click="onSubmit">下一步
            </el-button>
          </el-form-item>
        </el-form>
        <div class="tips" v-if="isshowAddress">
          <p>拖车分布表包含陕西省西安市以下主要地区，拖车区域如下：</p>
          <div>
            <el-table :show-header="false" :border="true" :data="gridData">
              <el-table-column align="center" property="address" label="地区"></el-table-column>
            </el-table>
            <el-table :show-header="false" :border="true" :data="gridDataTwo">
              <el-table-column align="center" property="address" label="地区"></el-table-column>
            </el-table>
          </div>
          <p>*其他省市地区不提供拖车服务</p>
        </div>
      </div>
    </el-card>

  </div>
</template>

<script>
import { regionData, CodeToText } from "element-china-area-data";
import { scrapOne, getAreaList } from "../../api/personalCenter";
export default {
  name: "fillInTheOrder",
  data() {
    return {
      isshowAddress: false,
      isShowCheckedMsg: false,
      provinceList: [],
      radio: 1,
      active: 0,
      form: {
        platenumber: "",
        name: "",
        phone: "",
        sparePhone: "",
        address: "",
        addressInfo: "",
        postcode: "",
        type: [],
        status: "",
        province: "",
        city: "",
        area: "",
        remarks: "",
      },
      checked: false,
      rules: {
        platenumber: [
          {
            required: true,
            message: "请输入车牌号",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            validator: this.checkPhone,
            message: "请输入手机号码",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            validator: this.addressValidator,
            message: "请选择区地址",
            trigger: "change",
          },
        ],
        addressInfo: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: "blur",
          },
        ],
      },
      options: regionData,
      selectedOptions: [],
      addtions: {}, //存储地址数据
      gridData: [
        {
          address: "灞桥",
        },
        {
          address: "碑林",
        },
        {
          address: "高陵",
        },
        {
          address: "高新",
        },
        {
          address: "鄠邑",
        },
        {
          address: "经开",
        },
        {
          address: "蓝田",
        },
        {
          address: "莲湖",
        },
      ],
      gridDataTwo: [
        {
          address: "临潼",
        },
        {
          address: "未央",
        },
        {
          address: "曲江",
        },
        {
          address: "新城",
        },
        {
          address: "西咸新区",
        },
        {
          address: "阎良",
        },
        {
          address: "周至",
        },
        {
          address: "长安",
        },
        {
          address: "雁塔",
        },
      ],
    };
  },
  computed: {
    cityList() {
      const province = this.provinceList.find(
        (item) => item.value === this.form.province
      );
      return (province && province.children) || [];
    },
    areaList() {
      const city = this.cityList.find((item) => item.value === this.form.city);
      return (city && city.children) || [];
    },
    areaId() {
      this.form.address = this.form.area;
      return +this.form.area;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.path !== from.path && to.path.indexOf("/scrap/secondScrap") === -1) {
      window.sessionStorage.setItem("firstScrap", JSON.stringify({}));
    }
    next();
  },
  watch: {
    checked(newVal) {
      this.isShowCheckedMsg = !newVal;
    },
  },
  async created() {
    this.form = this.$route.query;
    const res = await getAreaList();
    this.provinceList = res.result;
    // this.form.province = this.provinceList[24].value
    // this.form.city = this.provinceList[24].children[6].value
    // this.form.area = this.provinceList[24].children[6].children[1].value
    let firstScrap = window.sessionStorage.getItem("firstScrap");
		console.log(firstScrap, this.form)
    //  && Object.keys(JSON.parse(firstScrap)).length > 0
    if (firstScrap && JSON.parse(firstScrap).CarOwner) {
      let firstScrapForm = JSON.parse(firstScrap);
      this.form = {
        name: firstScrapForm.CarOwner,
        phone: firstScrapForm.Phone,
        addressInfo: firstScrapForm.Address,
        areaId: firstScrapForm.AreaId,
        platenumber: firstScrapForm.Platenumber,
        ...firstScrapForm,
      };
      this.radio = firstScrapForm.ServiceMode + 1;
      if (this.radio == 2) {
        this.isshowAddress = true;
      }
      this.checked = firstScrapForm.checked || false;
    } else {
      this.form = {
        city: this.provinceList[24].children[6].value || "610100",
        Address: this.provinceList[24].children[6].children[1].value || "2202",
        province: this.provinceList[24].value || "610000",
        area: this.provinceList[24].children[6].children[1].value || "2202",
        AreaId: +this.provinceList[24].children[6].children[1].value || 2202,
				...this.form
      };
    }
  },
  methods: {
    onChangeRadio(value) {
      // console.log(value);
      if (value == 2) {
        this.isshowAddress = true;
      } else {
        this.isshowAddress = false;
      }
    },
    addressValidator(rule, value, callback) {
      if (this.areaId) {
        callback();
      } else {
        callback(new Error("请选择区地址"));
      }
    },
    checkPhone(rule, value, callback) {
      let reg = /^1[345789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    },
    goScrapWarn() {
      this.$router.push({
        path: "/scrapWarn/scrapWarn",
        query: this.form,
      });
    },
    goScrapData() {
      this.$router.push({
        path: "/scrapData/scrapData",
        query: this.form,
      });
    },
    onSubmit() {
      if (!this.checked) {
        this.isShowCheckedMsg = true;
      }
      if (this.areaId) {
        this.form.address = this.areaId;
      }
      this.$refs["form"].validate(async (valid) => {
        if (valid && this.checked) {
          const params = {
            CarOwner: this.form.name,
            Phone: this.form.phone,
            Address: this.form.addressInfo,
            AreaId: this.areaId,
            Platenumber: this.form.platenumber,
            ServiceMode: this.radio - 1,
            Remark: this.form.remarks,
          };
          let setFirstScrap = {
            ...params,
            ...this.form,
            checked: this.checked,
          };
          setFirstScrap.ServiceMode = this.radio - 1;
          window.sessionStorage.setItem(
            "firstScrap",
            JSON.stringify(setFirstScrap)
          );
          const res = await scrapOne(params);
          this.$router.push({
            path: `/scrap/secondScrap/${res.result.id}`,
          });
        } else {
          return false;
        }
      });
    },
    jump() {
      this.$router.push({
        path: "/scrapWarn/scrapWarn",
      });
    },
  },
};
</script>

<style lang="less" scoped>
@textHeight: 40px;
.box-card {
  width: 1200px; /* no */
  margin: 20px auto; /* no */
}
.clearfix {
  padding: 0 0 20px 0; /* no */
}
.title-body {
  width: 500px; /* no */
  display: inline-block;
}
.title-top {
  font-size: 18px; /* no */
  margin-right: 230px; /* no */
}
.title-bottom {
  cursor: pointer;
  font-size: 18px; /* no */
  color: #24a2aa;
}
.from-item {
  width: 36%;
}
.from-item-radio {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px; /* no */
}
.bottom-btn {
  background: #24a2aa;
  color: white;
  width: 180px; /* no */
  margin-right: 20px; /* no */
}
.scrap-textarea {
  /deep/.el-textarea__inner {
    font: 400 13.3333px Arial; /* no */
    padding: 5px 15px; /* no */
    letter-spacing: 0px; /* no */
    line-height: 20px; /* no */
  }
}

.checked-wrapper {
  display: flex;
  flex-direction: column;

  span {
    font-size: 12px; /* no */
    color: #f56c6c;
  }
}
.box {
  position: relative;
}
.tips {
  position: absolute;
  bottom: 10%;
  left: 45%;
  div {
    display: flex;
  }
  p {
    margin: 20px 0 20px 0; /* no */
    color: red;
  }
}

/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: #24a2aa;
  background: #24a2aa;
}

.steps-wrapper {
  width: 500px; /* no */
  float: right;
  /deep/.is-finish {
    color: #24a2aa !important;
    border-color: #24a2aa;
  }

  /deep/.el-step__main {
    padding-top: 5px; /* no */
  }
}

/deep/.el-form-item__label {
  width: 120px !important; /* no */
}

/deep/.el-form-item__content {
  margin-left: 120px !important; /* no */
}
</style>
